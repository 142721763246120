import * as signalR from "@microsoft/signalr";
import config from "src/configs";
import  notificationActions from "src/store/notification/actions";
import  chatActions  from "src/store/notification/actions";

import { useDispatch } from "react-redux";
const baseName ="https://stg-hub.razytech.com";
// process.env.REACT_APP_HUB_BASE;
export const useSignalR = () => {
  const { setItem} = chatActions;

  const hub = new signalR.HubConnectionBuilder();
  let connection;

  const setUpHub = (token) => {
    return hub
      .withUrl(`${baseName}/hub/chat`, {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Debug)
      .build();
  };
  const startHub = (token) => {
    if (!config.signalR) return;
    try {
      connection = setUpHub(token);
      connection
        .start()
        .then(()=>
          onReceiveNotify()
        )

        .catch((err) => {

          // console.log('Error while starting connection' + err)
        });

    } catch (error) {
      // console.log("start the hub error", error)
    }

    connection.onclose((error) => {
      // console.log("connection closed")
     // connect(connection);
    });
  };

  async function connect(conn) {
    if (!config.signalR) return;
    conn.start().catch(async(e) => {
      await sleep(5000);
      // console.log("Reconnecting Socket");

    });
  }
  async function sleep(msec) {
    return new Promise((resolve) => setTimeout(resolve, msec));
  }

  const closeHub = () => {
    if (connection) connection.stop();
  };

  const dispatch = useDispatch();
  const onReceiveNotify = () => {
   // debugger

    if (!config.signalR) return;
    connection.on("notification", (message) => {
     console.log("message",message);
      dispatch(notificationActions.setNotifications({...message}))
     // dispatch(AuthActions.getNotifications({page: 1, pageSize: 1000 }))


    });
    // connection.on("chat", (message) => {
    //   // dispatch(AuthActions.setNotifications({...message}))
    //    callback&&callback(message)
    //   // dispatch(AuthActions.addNotification({ ...message, createdDate: new Date() }))
    //  });
   
  };
  return {
    startHub,
    onReceiveNotify,
    closeHub,
  };
};
