export default function (/**@type {ApisauceInstance} */ api) {
  const login = (loginRequest) => api.post("auth/login", loginRequest);
  const register = (loginRequest) => api.post("auth/register", loginRequest);
  const loginExternal = (payload) => api.post("auth/login-external", payload);
  const forgetPasswordOtp = (payload) => api.post("auth/forget-password-otp", payload);
  const resetPasswordOtp = (payload) => api.post("auth/reset-password-otp", payload);
  const updateProfile = (loginRequest) =>
    api.post("auth/update-profile", loginRequest);
  const changePassword = (loginRequest) =>
    api.post("auth/change-password", loginRequest);
  const logout = (accessToken) => api.post("auth/logout", { accessToken });
  const getNotifications = (payload) => api.get("notification/my-notification", payload);
  const readNotification = (id) => api.put("notification/read-notification/" + id);
  const refreshToken = (payload) => api.post("auth/refresh-token/", payload);
  return {
    auth: {
      login,
      register,
      forgetPasswordOtp,
      resetPasswordOtp,
      loginExternal,
      logout,
      getNotifications,
      readNotification,
      updateProfile,
      changePassword,
      refreshToken,
    },
  };
}
