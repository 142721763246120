
  
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("clients", { metadata: undefined }, []),
  ...defaultState("client", { metadata: undefined }, {}),
};
const setRegister=(state, draft, payload) => {
  draft.registerClients_errors = undefined;

};
export const reducer = createReducer(initialState, {         
  ...bindReducers(ActionTypes, {
     action: "getAllClients",
     stateName: "clients",
     async: true,
     isPaginated: true
  }),
  ...bindReducers(ActionTypes, {
     action: "getClientWithId",
     stateName: "client",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "getClientInfo",
    stateName: "clientInfo",
    async: true,
    isPaginated: false
 }),
  ...bindReducers(ActionTypes, {
    action: "createClient",
    stateName: "client",
    async: true,
    isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "registerClient",
    stateName: "registerClients",
    async: true,
    isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "setRegisterClient",
    stateName: "setRegisterClientRes",
    async: false,
    isPaginated: false,
    successCb: setRegister,
  }),
  ...bindReducers(ActionTypes, {
     action: "updateClient",
     stateName: "client",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteClient",
    stateName: "client",
    async: true,
    isPaginated: false
  }),
});
  