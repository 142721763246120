export default function (/**@type {ApisauceInstance} */ api) {
  const getReportsList = () => api.get("/reports/reports-list");
  const getProductAndCodesCount = () =>
    api.get("/reports/count-of-products-codes");
  const getLastDaysSummaryOfRedemptionAndScan = (payload) =>
    api.get("/reports/summary-redemption-scan", payload);
  const getServicesRedemptionSummary = (payload) =>
    api.get("/reports/summary-services-redemption", payload);
  const getTopTenScannedProductsQuery = (payload) =>
    api.get("/reports/top-products", payload);
  const getUsersCount = () => api.get("/Auth-reports/users-count");
  const generateFile = ({ path, filter }) =>
    api.get(`/reports/${path}`, filter);

  return {
    report: {
      getReportsList,
      getProductAndCodesCount,
      getLastDaysSummaryOfRedemptionAndScan,
      getServicesRedemptionSummary,
      getTopTenScannedProductsQuery,
      getUsersCount,
      generateFile,
    },
  };
}
