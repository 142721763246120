import { Trans } from "@lingui/react";
import React from "react";

function TransKeyNotFound() {
  return (
    <div className="d-none">
      <Trans id="search" />
      <Trans id="PhoneNumber must be unique" />
      <Trans id="NationalId must be unique" />
      <Trans id="Code Already Used" />

      <Trans id="Code not found" />

    </div>
  );
}

export default TransKeyNotFound;
