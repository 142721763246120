import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";
import { push } from "connected-react-router";

const apiNamespace = "product";

const getProductWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PRODUCT_WITH_ID,
});
const getAllProductsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_PRODUCTS,
});
const getPublishedProductsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PUBLISHED_PRODUCTS,
});
const createProductLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_PRODUCT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/product/list"));
  },
});

const updateProductLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_PRODUCT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/product/list"));
  },
});

const deleteProductLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_PRODUCT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});
const getAllProductAttributesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_PRODUCT_ATTRIBUTES,
});
const createProductAttributeLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_PRODUCT_ATTRIBUTE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/product/list"));
  },
});

const updateProductAttributeLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_PRODUCT_ATTRIBUTE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/product/list"));
  },
});

const deleteProductAttributeLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_PRODUCT_ATTRIBUTE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getProductWithIdLogic,
  getPublishedProductsLogic,
  getAllProductsLogic,
  createProductLogic,
  updateProductLogic,
  deleteProductLogic,
  getAllProductAttributesLogic,
  createProductAttributeLogic,
  updateProductAttributeLogic,
  deleteProductAttributeLogic,
];
