import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllAttributes: { args: ["payload"], meta: { async: true } },
    getAttributeWithId: { args: ["payload"], meta: { async: true } },
    createAttribute: { args: ["payload"], meta: { async: true } },
    updateAttribute: { args: ["payload"], meta: { async: true } },
    deleteAttribute: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/attribute/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
