
  
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("suggestions", { metadata: undefined }, []),
  ...defaultState("suggestion", { metadata: undefined }, {}),
};
const handleSeenSuccess=(state, draft, payload) => {
  const index = state.suggestions.findIndex(r => r.id === payload.id)
  if (index >= 0) {
    draft.suggestions[index] = payload;
  }
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {   
  ...bindReducers(ActionTypes, {
    action: "handleSeen",
    stateName: "suggestion",
    async: true,
    isPaginated: false,
   successCb: handleSeenSuccess,
  }),      
  ...bindReducers(ActionTypes, {
     action: "getAllSuggestions",
     stateName: "suggestions",
     async: true,
     isPaginated: true
  }),
  ...bindReducers(ActionTypes, {
     action: "getSuggestionWithId",
     stateName: "suggestion",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "createSuggestion",
    stateName: "suggestion",
    async: true,
    isPaginated: false
 }),
  ...bindReducers(ActionTypes, {
    action: "deleteSuggestion",
    stateName: "suggestion",
    async: true,
    isPaginated: false
  }),
  
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "createContactUs",
    stateName: "suggestion",
    async: true,
    isPaginated: false,
  
  }),
  
});
  