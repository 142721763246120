import { createReducer } from "reduxsauce";
import { produce } from "immer";
import jwtDecoder from "jwt-decode";

import { ActionTypes } from "./actions";
import { bindReducers, defaultState } from "src/utils/genState";

const initialState = {
  user: null,
  isLoggedIn: false,
  token: null,
  loginError: undefined,
  notifications: [],
  mobile:undefined

};

const login = (state) => {
  return produce(state, (draft) => {
    draft.loginLoading = true;
  });
};
const loginSuccess = (state, draft, payload) => {
  //console.log("payload",payload)
  const user = jwtDecoder(payload.token);
  draft.user = user;
  draft.isLoggedIn = true;
  draft.loginLoading = false;
  const roles = JSON.parse(user?.role);
  localStorage.setItem('userData', JSON.stringify(user))
  localStorage.setItem('jwtToken', payload.token)
  localStorage.setItem('refreshToken', payload.refreshToken)
};

const loginFail = (state, { payload }) => {
  return produce(state, (draft) => {
    draft.loginLoading = false;
    draft.loginError = payload.message;
  });
};
const loginExternal = (state) => {
  return produce(state, (draft) => {
    draft.loginExternalLoading = true;
  });
};
const setNotifications=(state, draft, payload) => {
  //debugger;
draft.notifications?.push(payload)
};
const loginExternalSuccess = (state, draft, payload) => {
  //debugger;
  const user = jwtDecoder(payload.token);
  draft.user = user;
  draft.isLoggedIn = true;
  draft.loginLoading = false;
  localStorage.setItem("userData", JSON.stringify(user));
  localStorage.setItem("jwtToken", payload.token);
};

const loginExternalFail = (state, draft, payload) => {
  draft.loginExternalLoading = false;
  draft.loginExternalLoadingError = payload.errorMessage;
};
const updateTokenSuccess = (state, draft, payload) => {
  const user = jwtDecoder(payload);
  if (user.active === false) {
    localStorage.removeItem("userData");
    localStorage.removeItem("jwtToken");
    draft.user = undefined;
    draft.isLoggedIn = false;
    return;
  }
  draft.user = user;
  draft.loginLoading = false;
  localStorage.setItem("userData", JSON.stringify(user));
  localStorage.setItem("jwtToken", payload);
};
const updateUserScoreSuccess = (state, draft, payload) => {
  draft.user = { ...draft.user, score: payload };
};

const logout = (state, { payload }) => {
  return produce(state, (draft) => {
    localStorage.removeItem("userData");
    localStorage.removeItem("jwtToken");
    draft.user = undefined;
    draft.isLoggedIn = false;
  });
};

const getNotifications = (state) => {
  return produce(state, (draft) => {
    draft.loginLoading = true;
  });
};
// const getNotificationsSuccess = (state, { payload }) => {
//   //console.log("data",payload.items);
//   //debugger
//   return produce(state, (draft) => {
//     draft.notifications = payload?.payload;
//     draft.loginLoading = false;
//   });
// };
const getNotificationsFail = (state, { payload }) => {
  return produce(state, (draft) => {
    draft.loginLoading = false;
    draft.loginError = payload.message;
  });
};
const addNotification = (state, { notify }) => {
  return produce(state, (draft) => {
    draft.loginLoading = false;
    draft.notifications = [notify, ...state.notifications];
  });
};
const readNotification = (state, { notify }) => {
  return produce(state, (draft) => {});
};
const readNotificationSuccess = (state, { payload }) => {
  return produce(state, (draft) => {
    draft.notifications.forEach((a) => {
      if (a.notifyId === payload.data.notficiationId) a.read = true;
    });
  });
};
const readNotificationFailed = (state, { notify }) => {
  return produce(state, (draft) => {});
};
const resetPasswordSuccess = (state, draft, payload) => {
  draft.changePassword_errors = null;
};
const forgetPasswordOtpSuccess=(state, draft, payload) => {
  debugger
  console.log("dddd",payload);
}
const setRegister=(state, draft, payload) => {
 // debugger
  //draft.registerRes = undefined;
  //draft.registerRes_errors = undefined;
  draft.mobile=payload
 // draft.isOpenSweetAlert=false;
  //draft.forgetPasswordOtpRes=undefined;

};
const setMobile=(state, draft, payload) => {
 // debugger
  draft.mobile=payload
};

export const authReducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "login",
    stateName: "login",
    async: true,
    isPaginated: false,
    successCb: loginSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "forgetPasswordOtp",
    stateName: "forgetPasswordOtpRes",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setNotifications",
    stateName: "setNotificationsRes",
    async: false,
    isPaginated: false,
    successCb:setNotifications
  }),
  ...bindReducers(ActionTypes, {
    action: "resetPasswordOtp",
    stateName: "resetPasswordOtpRes",
    async: true,
    isPaginated: false,
    successCb:forgetPasswordOtpSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "register",
    stateName: "registerRes",
    async: true,
    isPaginated: false,
    //successCb: registerSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "setRegister",
    stateName: "setRegisterRes",
    async: false,
    isPaginated: false,
    successCb: setRegister,
  }),
  ...bindReducers(ActionTypes, {
    action: "setMobile",
    stateName: "setMobileRes",
    async: false,
    isPaginated: false,
    successCb: setMobile,
  }),

  ...bindReducers(ActionTypes, {
    action: "loginExternal",
    stateName: "loginExternal",
    async: true,
    isPaginated: false,
    successCb: loginExternalSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateToken",
    stateName: "updateToken",
    async: false,
    isPaginated: false,
    successCb: updateTokenSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateProfile",
    stateName: "updateProfile",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "changePassword",
    stateName: "changePassword",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetChangePassword",
    stateName: "resetChangePassword",
    successCb: resetPasswordSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateUserScore",
    async: false,
    isPaginated: false,
    successCb: updateUserScoreSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getNotifications",
    stateName: "notifications",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "refreshToken",
    async: true,
    isPaginated: false,
  }),

  [ActionTypes.LOGOUT]: logout,
 // [ActionTypes.GET_NOTIFICATIONS]: getNotifications,
 // [ActionTypes.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
 // [ActionTypes.GET_NOTIFICATIONS_FAIL]: getNotificationsFail,
  [ActionTypes.ADD_NOTIFICATION]: addNotification,
  [ActionTypes.READ_NOTIFICATION]: readNotification,
  [ActionTypes.READ_NOTIFICATION_SUCCESS]: readNotificationSuccess,
  [ActionTypes.READ_NOTIFICATION_FAIL]: readNotificationFailed,
});
