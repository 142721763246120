export default function (/**@type {ApisauceInstance} */ api) {
    const getAllQuestions = (paging) => api.get("/FastQustion/search", paging);
  
    const getQuestionWithId = (id) => api.get("/FastQustion/find/" + id);
  
    const createQuestion = (payload) => api.post("/FastQustion/create", payload);
  
    const updateQuestion = (payload) => api.put("/FastQustion/edit", payload);
  
    const deleteQuestion = (id) => api.delete("/FastQustion/remove/" + id);
  
    return {
      question: {
        getAllQuestions,
        getQuestionWithId,
        createQuestion,
        updateQuestion,
        deleteQuestion,
      },
    };
  }
  