import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {

    getAdvertisement: { args: ["payload"], meta: { async: true } },
    saveAdvertisement: { args: ["payload"], meta: { async: true } },
    deleteAdvertisement: { args: ["payload"], meta: { async: true } },
    updateAdvertisement: { args: ["payload"], meta: { async: true } },

    setAdvertisement: { args: ["payload"], meta: { async: false } },


    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/Advertisements/",
  }
);
export const ActionTypes = Types;
export default Creators;
