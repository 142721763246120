import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("categories", { metadata: undefined }, []),
  ...defaultState("parentCategories", { metadata: undefined }, []),
  ...defaultState("category", { metadata: undefined }, {}),
};

const categoryEditedSuccess = (state, draft, payload) => {
  const categories = state.categories || [];
  var categoryIndex = categories.findIndex((u) => u.id === payload.id);
  draft.categories[categoryIndex] = payload;
  draft.openDialog = false;
};

const categoryCreatedSuccess = (state, draft, payload) => {
  const categories = state.categories || [];
  draft.categories = [...categories, payload];
  draft.openDialog = false;
};

const categoryDeleteSuccess = (state, draft, payload) => {
  const categories = state.categories || [];
  draft.categories = categories.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};
const setCategory = (state, draft, payload) => {
  draft.category = undefined;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

export const reducer = createReducer(initialState, {
  //**Categories
  ...bindReducers(ActionTypes, {
    action: "getAllCategories",
    stateName: "categories",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getParentCategories",
    stateName: "parentCategories",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getCategoryWithId",
    stateName: "category",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createCategory",
    stateName: "category",
    async: true,
    isPaginated: false,
    successCb: categoryCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateCategory",
    stateName: "category",
    async: true,
    isPaginated: false,
    successCb: categoryEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteCategory",
    stateName: "category",
    async: true,
    isPaginated: false,
    successCb: categoryDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "setCategory",
    stateName: "category",
    async: false,
    isPaginated: false,
    successCb: setCategory,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
