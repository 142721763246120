
import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getScibWalletTransactions: { args: ["payload"], meta: { async: true } },
    addPoints: { args: ["payload"], meta: { async: true } },
    getPoints: { args: ["payload"], meta: { async: true } },
    setDestination: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },

    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },

  },
  {
    prefix: "@app/scibWalletTransactions/"
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions=Creators;

    