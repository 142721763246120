import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("transactions", { metadata: undefined }, []),
  ...defaultState("ownerTransactions", { metadata: undefined }, []),
  ...defaultState("serviceTransactions", { metadata: undefined }, []),
  ...defaultState("gift", { metadata: undefined }, {}),
  ...defaultState("points", { metadata: undefined }, 0),
  ...defaultState("updatedPoints", { metadata: undefined }, 0),
  ...defaultState("transaction", { metadata: undefined }, {}),
  ...defaultState("exportedOwnerTransactions", { metadata: undefined }, []),

};
const createGiftPointsSuccess = (state, draft, payload) => {
  const transactions = state.ownerTransactions || [];
  draft.ownerTransactions = [payload, ...transactions];
  draft.updatedPoints = payload?.balance;
  draft.openDialog = false;
};
const getClientPointsSuccess = (state, draft, payload) => {
  draft.points = payload.balance;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};
const resetDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = false;
};


const setItem = (state, draft, payload) => {
  const { item, data } = payload;
  draft[item] = data;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getUserTransactions",
    stateName: "transactions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTransactionsWithOwnerId",
    stateName: "ownerTransactions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTransactionsWithServiceId",
    stateName: "ownerTransactions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTransactionWithWalletId",
    stateName: "ownerTransactions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "chargeProviderWallet",
    stateName: "providerWallet",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "exportTransactionsWithOwnerToExcel",
    stateName: "exportedOwnerTransactions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "exportTransactionsWithServiceToExcel",
    stateName: "exportedOwnerTransactions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "exportUsersTransactionsSummary",
    stateName: "usersTransactionsSummary",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "exportTransactionsWithWalletToExcel",
    stateName: "exportedOwnerTransactions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTransactionWithId",
    stateName: "transaction",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createGiftPoints",
    stateName: "gift",
    async: true,
    isPaginated: false,
    successCb: createGiftPointsSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "scanCode",
    stateName: "scannedCode",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setItem",
    stateName: "setItem",
    async: false,
    isPaginated: false,
    successCb: setItem,
  }),
  ...bindReducers(ActionTypes, {
    action: "getClientPoints",
    stateName: "points",
    async: true,
    isPaginated: false,
    successCb: getClientPointsSuccess
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDeleteDialog",
    stateName: "resetDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: resetDeleteDialog,
  }),
});
