import { createLogic } from "redux-logic";
import { ActionTypes } from "./actions";
import logic from "src/utils/genLogic";
import { push } from "connected-react-router";
import jwtDecode from "jwt-decode";
import { Trans } from "@lingui/react";

const apiNamespace = "auth";
const getNotificationsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_NOTIFICATIONS,
});
export const readNotificationLogic = createLogic({
  type: ActionTypes.READ_NOTIFICATION,
  latest: true,

  async process({ getState, action, api }, dispatch, done) {
    try {
      const res = await api.auth.readNotification(action.payload);
      if (!res.ok || !res.data.success) {
        dispatch({
          type: ActionTypes.READ_NOTIFICATION_FAIL,
          payload: res.data || "Unknown Error",
          error: true,
        });
      } else {
        dispatch({
          type: ActionTypes.READ_NOTIFICATION_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.READ_NOTIFICATION_FAIL,
        payload: err,
        error: true,
      });
    }

    done();
  },
});
const loginLogic = logic(apiNamespace, {
  actionName: ActionTypes.LOGIN,
  showErrorMessage: true,
  errorMessage: "invalid_login ",
  showSuccessMessage: false,
  // successMessage:"Welcome back",
  successCb: (dispatch, payload) => {
    dispatch(push("/home"));
  },
});
const registerLogic = logic(apiNamespace, {
  actionName: ActionTypes.REGISTER,
  showErrorMessage: true,
  errorMessage: "Invalid Registeration ",
  showSuccessMessage: false,
  // successMessage:"Welcome back",
  successCb: (dispatch, payload) => {
    dispatch(push("/verify-otp"));
  },
  
});
const refreshTokenLogic = logic(apiNamespace, {
  actionName: ActionTypes.REFRESH_TOKEN,
});
const forgetPasswordOtpLogic = logic(apiNamespace, {
  actionName: ActionTypes.FORGET_PASSWORD_OTP,
  showErrorMessage: true,
  errorMessage: <Trans id="Wrong_mobile_or_national_id"/>,
  showSuccessMessage: false,
  successMessage:<Trans id="success"/>,
  successCb: (dispatch,payload) => {
    dispatch(push("/reset-password-otp"));
  },

});
const resetPasswordOtpLogic = logic(apiNamespace, {
  actionName: ActionTypes.RESET_PASSWORD_OTP,
  showErrorMessage: true,
  errorMessage: <Trans id="invalid"/>,
  showSuccessMessage: false,
  successMessage:<Trans id="success"/>,
  successCb: (dispatch) => {
    dispatch(push("/login"));
  },

});
export const loginExternalLogic = logic(apiNamespace, {
  actionName: ActionTypes.LOGIN_EXTERNAL,
  showErrorMessage: true,
  errorMessage: <Trans id="invalid_login"/>,
  showSuccessMessage: false,
  // successMessage:"Welcome back",
  successCb: (dispatch, payload) => {
    dispatch(push("/home"));
  },
});

const changePasswordLogic = logic(apiNamespace, {
  actionName: ActionTypes.CHANGE_PASSWORD,
  showErrorMessage: true,
  errorMessage: <Trans id="faild_to_change_your password"/>,
  showSuccessMessage: true,
 successMessage: <Trans id="your_password_updated_successfully"/>,
});
const updateProfileLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_PROFILE,
  showErrorMessage: true,
  errorMessage: "Faild to update your profile",
  showSuccessMessage: true,
  successMessage: "Your profile updated successfully",
});

export default [
  changePasswordLogic,
  updateProfileLogic,
  loginLogic,
  registerLogic,
  loginExternalLogic,
  getNotificationsLogic,
  readNotificationLogic,
  refreshTokenLogic,
  forgetPasswordOtpLogic,
  resetPasswordOtpLogic
];
