export default function (/**@type {ApisauceInstance} */ api) {
  const getAllCategories = (paging) => api.get("/category/search", paging);
  const getParentCategories = (paging) => api.get("/category/search", paging);
  const getCategoryWithId = (id) => api.get("/category/find/" + id);

  const createCategory = (payload) => api.post("/category/create", payload);

  const updateCategory = (payload) => api.put("/category/edit", payload);

  const deleteCategory = (id) => api.delete("/category/remove/" + id);

  return {
    category: {
      getAllCategories,
      getCategoryWithId,
      createCategory,
      updateCategory,
      deleteCategory,
      getParentCategories
    },
  };
}
