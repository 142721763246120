import appLogic from "./app/logic";
import authLogic from "./auth/logic";
import usersLogic from "./userManagement/logic";
import settingLogic from "./setting/logic";
import tenantLogic from "./tenant/logic";
import storeLogic from "./store/logic";
import categoryLogic from "./category/logic";
import productLogic from "./product/logic";
import attributeLogic from "./attribute/logic";
import codeLogic from "./code/logic";
import cityLogic from "./city/logic";
import questionLogic from "./question/logic";
import suggestionLogic from "./suggestion/logic";
import transactionLogic from "./transaction/logic";
//---------------------------
import companyInfoLogic from "./companyInfo/logic";
import dashboardLogic from "./dashboard/logic";
import parentServiceLogic from "./service/logic";
import notificationLogic from "./notification/logic";
import productSkuLogic from "./productSku/logic";
import slapLogic from "./slap/logic";
import advertisementLogic from "./advertisement/logic";
import termsLogic from "./terms/logic";
import scibWalletTransactions from "./scibWallet/logic";
import reportsLogic from "./report/logic";
import clientLogic from "./client/logic";
import requestLogic from "./request/logic";


export default [
  ...appLogic,
  ...authLogic,
  ...usersLogic,
  ...settingLogic,
  ...companyInfoLogic,
  ...dashboardLogic,
  ...tenantLogic,
  ...storeLogic,
  ...categoryLogic,
  ...productLogic,
  ...attributeLogic,
  ...codeLogic,
  ...cityLogic,
  ...questionLogic,
  ...parentServiceLogic,
  ...suggestionLogic,
  ...transactionLogic,
  ...notificationLogic,
  ...productSkuLogic,
  ...slapLogic,
  ...advertisementLogic,
  ...termsLogic,
  ...scibWalletTransactions,
  ...reportsLogic,
  ...clientLogic,
  ...requestLogic
];
