// import { createLogic } from "redux-logic";
import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push ,goBack} from "connected-react-router";
import { Trans } from "@lingui/react";

const apiNamespace = "userManegment";
const getUsersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_USERS,
  showErrorMessage: true,
  showSuccessMessage: false,
});

const getUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_USER,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const verifyOtpLogic = logic(apiNamespace, {
  actionName: ActionTypes.VERIFY_OTP,
  showErrorMessage: true,
  showSuccessMessage: true,
  successMessage: <Trans id="otp_verified_successfully"/>,
  errorMessage:<Trans id="invalid"/>,
  successCb:(dispatch)=>{
    dispatch(push("/login"));
  }
});
const resendOtpLogic = logic(apiNamespace, {
  actionName: ActionTypes.RESEND_OTP,

  
});
const getProfileDataLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PROFILE_DATA,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const handleSeenLogic = logic(apiNamespace, {
  actionName: ActionTypes.HANDLE_SEEN,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const getPermissionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PERMISSIONS,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const createUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_USER,
  showErrorMessage: true,
  successMessage: "user created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const createClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CLIENT,
  showErrorMessage: true,
  successMessage: "user created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(goBack());
  },
});
const editClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_CLIENT,
  showErrorMessage: true,
  successMessage: "user created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(goBack());
  },
});
const updateUserAvatarLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_USER_AVATAR,
  showErrorMessage: true,
  successMessage:<Trans id="photo_updated_successfully" />,
  showSuccessMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,

});
const addClientDecisionLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_CLIENT_DECISION,
  showErrorMessage: true,
  successMessage: "request updated successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/requests/list"));
  },
});
const createUsersLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_USERS,
  showErrorMessage: true,
  successMessage: "users created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const createAppUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_APP_USER,
  showErrorMessage: true,
  successMessage: "user created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const createRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_ROLE,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/roles/list"));
  },
});
const getRolesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ROLES,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const getRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ROLE,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const deleteUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_USER,
  showErrorMessage: true,
  successMessage: "user Deleted successfully",
  showSuccessMessage: true,
});
const editUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_USER,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
   successCb: (dispatch) => {
  //   dispatch(push("/user-managment/users/list"));
  dispatch(goBack())
 },
});
const editRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_ROLE,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/roles/list"));
  },
});
const deleteRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_ROLE,
  showErrorMessage: true,
  successMessage: "Role Deleted successfully",
  showSuccessMessage: true,
});
const registerLogic = logic(apiNamespace, {
  actionName: ActionTypes.REGISTER,
  showErrorMessage: true,
  errorMessage: <Trans id="invalid_registeration"/>,
  showSuccessMessage: false,
  // successMessage:"Welcome back",
  successCb: (dispatch, payload) => {
    dispatch(push("/verify-otp"));
  },
  
});

export default [
  registerLogic,
  getUsersLogic,
  getProfileDataLogic,
  createUserLogic,
  resendOtpLogic,
  handleSeenLogic,
  createClientLogic,
  editClientLogic,
  verifyOtpLogic,
  createUsersLogic,
  updateUserAvatarLogic,
  createAppUserLogic,
  getRolesLogic,
  getUserLogic,
  deleteUserLogic,
  editUserLogic,
  createRoleLogic,
  getPermissionsLogic,
  deleteRoleLogic,
  editRoleLogic,
  getRoleLogic,
  addClientDecisionLogic,
];
