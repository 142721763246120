export default function (/**@type {ApisauceInstance} */ api) {
  const getAllProducts = (paging) =>
    api.get("/product/search", { ...paging, activeOnly: true });
    const getPublishedProducts = (paging) =>
    api.get("/product/feature-products", paging);
  //const getProductWithId = (id) => api.get("/product/find/" + id);

  const getProductWithId = (id) => api.get("/product/find-product/" + id);

  const createProduct = (payload) => api.post("/product/create", payload);

  const updateProduct = (payload) => api.put("/product/edit", payload);

  const deleteProduct = (id) => api.delete("/product/remove/" + id);
  // product attributes
  const getAllProductAttributes = (paging) =>
    api.get("/product/product-attribute/search", paging);

  const createProductAttribute = (payload) =>
    api.post("/product/product-attribute/create", payload);

  const updateProductAttribute = (payload) =>
    api.put("/product/product-attribute/edit", payload);

  const deleteProductAttribute = (id) =>
    api.delete("/product/product-attribute/remove/" + id);

  return {
    product: {
      getAllProducts,
      getPublishedProducts,
      getProductWithId,
      createProduct,
      updateProduct,
      deleteProduct,
      getAllProductAttributes,
      createProductAttribute,
      updateProductAttribute,
      deleteProductAttribute,
    },
  };
}
