
  
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("slaps", { metadata: undefined }, []),
  ...defaultState("slap", { metadata: undefined }, {}),
};
const slapEditedSuccess = (state, draft, payload) => {
  const slaps = state.slaps || [];
  var slapIndex = slaps.findIndex((u) => u.id === payload.id);
  draft.slaps[slapIndex] = payload;
  draft.openDialog = false;
};

const slapCreatedSuccess = (state, draft, payload) => {
  const slaps = state.slaps || [];
  draft.slaps = [...slaps, payload];
  draft.openDialog = false;
};
const slapDeleteSuccess = (state, draft, payload) => {
  // debugger;
   const slaps = state.slaps || [];
   draft.slaps = slaps.filter((a) => a.id != payload);
   draft.openDeleteDialog = false;
 };
 const setSlap=(state,draft,payload)=>{
   draft.slap=undefined;
 }
 const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
 const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.user_errors = [];
};

export const reducer = createReducer(initialState, {         
  ...bindReducers(ActionTypes, {
     action: "getAllSlaps",
     stateName: "slaps",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
     action: "getSlapWithId",
     stateName: "slap",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "createSlap",
    stateName: "slap",
    async: true,
    isPaginated: false,
    successCb:slapCreatedSuccess
  }),
  ...bindReducers(ActionTypes, {
     action: "updateSlap",
     stateName: "slap",
     async: true,
     isPaginated: false,
    successCb:slapEditedSuccess

  }),
  ...bindReducers(ActionTypes, {
    action: "deleteSlap",
    stateName: "slap",
    async: true,
    isPaginated: false,
    successCb:slapDeleteSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "setSlap",
    stateName: "slap",
    async: false,
    isPaginated: false,
    successCb:setSlap,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: true,
    successCb: resetDialog,
  }),
});
  