import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("products", { metadata: undefined }, []),
  ...defaultState("product", { metadata: undefined }, {}),
};

const productEditedSuccess = (state, draft, payload) => {
  const products = state.products || [];
  var productIndex = products.findIndex((u) => u.id === payload.id);
  draft.products[productIndex] = payload;
};

const productCreatedSuccess = (state, draft, payload) => {
  const products = state.products || [];
  draft.products = [...products, payload];
  draft.openDialog = false;
};

const productDeleteSuccess = (state, draft, payload) => {
  const products = state.products || [];
  draft.products = products.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};

const setProduct = (state, draft, payload) => {
  draft.product = undefined;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

export const reducer = createReducer(initialState, {
  //**Categories
  ...bindReducers(ActionTypes, {
    action: "getProductWithId",
    stateName: "product",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllProducts",
    stateName: "products",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getPublishedProducts",
    stateName: "publishedProducts",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createProduct",
    stateName: "product",
    async: true,
    isPaginated: false,
    successCb: productCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateProduct",
    stateName: "product",
    async: true,
    isPaginated: false,
    successCb: productEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteProduct",
    stateName: "product",
    async: true,
    isPaginated: false,
    successCb: productDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllProductAttributes",
    stateName: "productAttributes",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createProductAttribute",
    stateName: "productAttribute",
    async: true,
    isPaginated: false,
    successCb: productCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateProductAttribute",
    stateName: "productAttribute",
    async: true,
    isPaginated: false,
    successCb: productEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteProductAttribute",
    stateName: "productAttribute",
    async: true,
    isPaginated: false,
    successCb: productDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "setProduct",
    stateName: "product",
    async: false,
    isPaginated: false,
    successCb: setProduct,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
