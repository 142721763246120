export default function (/**@type {ApisauceInstance} */ api) {
    const getNewRequest = (payload) =>
      api.get("/user-request/new-request", payload);
    const getRequest = (id) => api.get(`/user-request/get-request/${id}`);
    const getRequests = (payload) => api.get(`/user-request/requests`, payload);
  
    return {
      request: {
        getNewRequest,
        getRequest,
        getRequests,
      },
    };
  }
  