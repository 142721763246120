
   import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllClients: { args: ["payload"], meta: { async: true } },
    getClientWithId: { args: ["payload"], meta: { async: true } },
    getClientInfo: { args: ["payload"], meta: { async: true } },
    createClient: { args: ["payload"], meta: { async: true } },
    registerClient: { args: ["payload"], meta: { async: true } },
    setRegisterClient: { args: ["payload"], meta: { async: false } },

    updateClient: { args: ["payload"], meta: { async: true } },
    deleteClient: { args: ["payload"], meta: { async: true } },
  },
  {
    prefix: "@app/Client/"
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions=Creators;

    