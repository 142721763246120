export default function (/**@type {ApisauceInstance} */ api) {
  const getAllAttributes = (paging) => api.get("/attribute/search", paging);

  const getAttributeWithId = (id) => api.get("/attribute/find/" + id);

  const createAttribute = (payload) => api.post("/attribute/create", payload);

  const updateAttribute = (payload) => api.put("/attribute/edit", payload);

  const deleteAttribute = (id) => api.delete("/attribute/remove/" + id);

  return {
    attribute: {
      getAllAttributes,
      getAttributeWithId,
      createAttribute,
      updateAttribute,
      deleteAttribute,
    },
  };
}
