
import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";

const apiNamespace = "slap";

const getAllSlapsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_SLAPS,
  
});
const getSlapWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SLAP_WITH_ID,
});
const createSlapLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_SLAP,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  // successCb: (dispatch) => {
  //   dispatch(push("/slap/list"));
  // }
});
const updateSlapLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_SLAP,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
//   successCb: (dispatch) => {
//     dispatch(push("/slap/list"));
// }
 });
const deleteSlapLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SLAP,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllSlapsLogic,
  getSlapWithIdLogic,
  createSlapLogic, 
  updateSlapLogic,
  deleteSlapLogic
];
  
  