
import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllQuestions: { args: ["payload"], meta: { async: true } },
    getQuestionWithId: { args: ["payload"], meta: { async: true } },
    createQuestion: { args: ["payload"], meta: { async: true } },
    updateQuestion: { args: ["payload"], meta: { async: true } },
    deleteQuestion: { args: ["payload"], meta: { async: true } },
    setQuestion: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/question/"
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions=Creators;

    