
import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";

const apiNamespace = "Client";

const getAllClientsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_CLIENTS,
});
const getClientWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CLIENT_WITH_ID,
});
const getClientInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CLIENT_INFO,
});
const createClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CLIENT,
});
const registerClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.REGISTER_CLIENT,
});
const updateClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_CLIENT,
});
const deleteClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_CLIENT,
});

export default [
  getAllClientsLogic,
  getClientWithIdLogic,
  getClientInfoLogic,
  createClientLogic,
  registerClientLogic, 
  updateClientLogic,
  deleteClientLogic
];
  
  