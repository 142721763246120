
import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";
import { push } from "connected-react-router";

const apiNamespace = "scibWalletTransactions";

const getScibWalletTransactionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SCIB_WALLET_TRANSACTIONS,
});
const addPointsLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_POINTS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb:()=>{
   // window.location.reload();
  }
});
const getPointsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_POINTS,
});
export default [
    getScibWalletTransactionsLogic,
    addPointsLogic,
    getPointsLogic

];
  
  