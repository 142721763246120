import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
const apiNamespace = "report";

const getReportsListLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REPORTS_LIST,
});
const getProductAndCodesCountLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PRODUCT_AND_CODES_COUNT,
});
const getLastDaysSummaryOfRedemptionAndScanLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_LAST_DAYS_SUMMARY_OF_REDEMPTION_AND_SCAN,
});
const getServicesRedemptionSummaryLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SERVICES_REDEMPTION_SUMMARY,
});
const getTopTenScannedProductsQueryLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TOP_TEN_SCANNED_PRODUCTS_QUERY,
});
const getUsersCountLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_USERS_COUNT,
});
const generateFileLogic = logic(apiNamespace, {
  actionName: ActionTypes.GENERATE_FILE,
});

export default [
  getReportsListLogic,
  getProductAndCodesCountLogic,
  getLastDaysSummaryOfRedemptionAndScanLogic,
  getServicesRedemptionSummaryLogic,
  getTopTenScannedProductsQueryLogic,
  getUsersCountLogic,
  generateFileLogic,
];
