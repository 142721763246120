import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";

const apiNamespace = "transaction";

const getUserTransactionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_USER_TRANSACTIONS,
});
const getTransactionsWithOwnerIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TRANSACTIONS_WITH_OWNER_ID,
});
const exportTransactionsWithOwnerToExcelLogic = logic(apiNamespace, {
  actionName: ActionTypes.EXPORT_TRANSACTIONS_WITH_OWNER_TO_EXCEL,
});
const exportTransactionsWithServiceToExcelLogic = logic(apiNamespace, {
  actionName: ActionTypes.EXPORT_TRANSACTIONS_WITH_SERVICE_TO_EXCEL,
});
const exportUsersTransactionsSummaryLogic = logic(apiNamespace, {
  actionName: ActionTypes.EXPORT_USERS_TRANSACTIONS_SUMMARY,
});
const exportTransactionsWithWalletToExcelLogic = logic(apiNamespace, {
  actionName: ActionTypes.EXPORT_TRANSACTIONS_WITH_WALLET_TO_EXCEL,
});
const getTransactionsWithServiceIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TRANSACTIONS_WITH_SERVICE_ID,
});
const getClientPointsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CLIENT_POINTS,
});
const chargeProviderWalletLogic = logic(apiNamespace, {
  actionName: ActionTypes.CHARGE_PROVIDER_WALLET,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const getTransactionWithWalletIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TRANSACTION_WITH_WALLET_ID,
});
const getTransactionWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TRANSACTION_WITH_ID,
});
const createGiftPointsLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_GIFT_POINTS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const scanCodeLogic = logic(apiNamespace, {
  actionName: ActionTypes.SCAN_CODE,
  showErrorMessage: false,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="product_scanned_successfully" />,
});

export default [
  getUserTransactionsLogic,
  getTransactionWithIdLogic,
  chargeProviderWalletLogic,
  createGiftPointsLogic,
  getClientPointsLogic,
  scanCodeLogic,
  getTransactionsWithOwnerIdLogic,
  getTransactionsWithServiceIdLogic,
  getTransactionWithWalletIdLogic,
  exportTransactionsWithOwnerToExcelLogic,
  exportTransactionsWithServiceToExcelLogic,
  exportTransactionsWithWalletToExcelLogic,
  exportUsersTransactionsSummaryLogic
];
