import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";

const apiNamespace = "companyInfos";

const getCompanyInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_COMPANY_INFO,
});
const saveCompanyInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.SAVE_COMPANY_INFO,
  showerrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

export default [getCompanyInfoLogic, saveCompanyInfoLogic];
