import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("productSkus", { metadata: undefined }, []),
  ...defaultState("productSku", { metadata: undefined }, {}),
};

const productSkuDeleteSuccess = (state, draft, payload) => {
  const skus = state.productSkus || [];
  draft.productSkus = skus.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};
export const reducer = createReducer(initialState, {         
  ...bindReducers(ActionTypes, {
    action: "getAllProductSkus",
    stateName: "productSkus",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getProductSkuWithId",
    stateName: "productSku",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createProductSku",
    stateName: "productSku",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
     action: "updateProductSku",
     stateName: "productSku",
     async: true,
     isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteProductSku",
    stateName: "productSku",
    async: true,
    isPaginated: false.valueOf,
    successCb:productSkuDeleteSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setProductSku",
    stateName: "productSku",
    async: false,
    isPaginated: false,
  }),
});
