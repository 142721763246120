export default function (/**@type {ApisauceInstance} */ api) {
    const getAllClients = (paging) => api.get("/Client/search", paging);
    const getClientWithId = (id) => api.get("/Client/find/" + id);
    const getClientInfo= (id) => api.get("/Client/get-my-info");

    const createClient = (payload) => api.post("/Client/create", payload);
    const registerClient = (payload) => api.post("/Client/register", payload);


    const updateClient = (payload) => api.put("/Client/edit", payload);
  
    const deleteClient = (id) => api.delete("/Client/remove/" + id);
  
    return {
      Client: {
        getAllClients,
        getClientWithId,
        createClient,
        registerClient,
        updateClient,
        deleteClient,
        getClientInfo
      },
    };
  }
  