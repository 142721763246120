import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";
import { push } from "connected-react-router";

const apiNamespace = "category";

const getAllCategoriesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_CATEGORIES,
});

const getParentCategoriesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PARENT_CATEGORIES,
});
const getCategoryWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CATEGORY_WITH_ID,
});
const createCategoryLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CATEGORY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/category/list"));
  },
});

const updateCategoryLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_CATEGORY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/category/list"));
  },
});

const deleteCategoryLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_CATEGORY,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllCategoriesLogic,
  getCategoryWithIdLogic,
  createCategoryLogic,
  updateCategoryLogic,
  deleteCategoryLogic,
  getParentCategoriesLogic
];
