
import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";
import { push } from "connected-react-router";

const apiNamespace = "question";

const getAllQuestionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_QUESTIONS,
});
const getQuestionWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_QUESTION_WITH_ID,
});
const createQuestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_QUESTION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/questions/list"));
  },

});
const updateQuestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_QUESTION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/questions/list"));
  },
});
const deleteQuestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_QUESTION,
});

export default [
  getAllQuestionsLogic,
  getQuestionWithIdLogic,
  createQuestionLogic, 
  updateQuestionLogic,
  deleteQuestionLogic
];
  
  