import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getTerms: { args: ["payload"], meta: { async: true } },
    updateTerms: { args: ["payload"], meta: { async: true } },
    createTerm: { args: ["payload"], meta: { async: true } },
    getTerm: { args: ["payload"], meta: { async: true } },
    deleteTerms: { args: ["payload"], meta: { async: true } },
    setTerm: { args: ["payload"], meta: { async: false } },


    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/terms/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
