import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("cities", { metadata: undefined }, []),
  ...defaultState("countries", { metadata: undefined }, []),
  ...defaultState("city", { metadata: undefined }, []),
  ...defaultState("country", { metadata: undefined }, []),
};

const editCountrySuccess = (state, draft, payload) => {
  const index = state.countries.findIndex(r => r.id === payload.id)
  if (index >= 0) {
    draft.countries[index] = payload;
  }
  draft.openDialog = false;
};
const countryDeleteSuccess = (state, draft, payload) => {
  const countries = state.countries || [];
  draft.countries = countries.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};
const countryCreatedSuccess = (state, draft, payload) => {
  const countries = state.countries || [];
  draft.countries = [...countries, payload];
  draft.openDialog = false;
};

const cityCreatedSuccess = (state, draft, payload) => {
  const cities = state.cities || [];
  draft.cities = [...cities, payload];
  draft.openDialog = false;
};

const editCitySuccess = (state, draft, payload) => {
  const index = state.cities.findIndex(r => r.id === payload.id)
  if (index >= 0) {
    draft.cities[index] = payload;
  }
  draft.openDialog = false;
};

const cityDeleteSuccess = (state, draft, payload) => {
  const cities = state.cities || [];
  draft.cities = cities.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};
const governorateCreatedSuccess = (state, draft, payload) => {
  const governorates = state.governorates || [];
  draft.governorates = [...governorates, payload];
  draft.openDialog = false;
};

const governorateEditSuccess = (state, draft, payload) => {
  const index = state.governorates.findIndex(r => r.id === payload.id)
  if (index >= 0) {
    draft.governorates[index] = payload;
  }
  draft.openDialog = false;
};

const governorateDeleteSuccess = (state, draft, payload) => {
  const governorates = state.governorates || [];
  draft.governorates = governorates.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllCountries",
    stateName: "countries",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createCountry",
    stateName: "country",
    async: true,
    isPaginated: false,
    successCb: countryCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editCountry",
    stateName: "country",
    async: true,
    isPaginated: false,
    successCb: editCountrySuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteCountry",
    stateName: "country",
    async: true,
    isPaginated: false,
    successCb: countryDeleteSuccess
  }),
  
  ...bindReducers(ActionTypes, {
    action: "getAllCities",
    stateName: "cities",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getCityWithId",
    stateName: "city",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createCity",
    stateName: "city",
    async: true,
    isPaginated: false,
    successCb: cityCreatedSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "editCity",
    stateName: "city",
    async: true,
    isPaginated: false,
    successCb: editCitySuccess
  }),

  ...bindReducers(ActionTypes, {
    action: "deleteCity",
    stateName: "city",
    async: true,
    isPaginated: false,
    successCb: cityDeleteSuccess
  }),
//Governorates
  ...bindReducers(ActionTypes, {
    action: "getAllGovernorates",
    stateName: "governorates",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createGovernorate",
    stateName: "governorate",
    async: true,
    isPaginated: false,
    successCb: governorateCreatedSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "editGovernorate",
    stateName: "governorate",
    async: true,
    isPaginated: false,
    successCb: governorateEditSuccess
  }),

  ...bindReducers(ActionTypes, {
    action: "deleteGovernorate",
    stateName: "governorate",
    async: true,
    isPaginated: false,
    successCb: governorateDeleteSuccess
  }),




  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
