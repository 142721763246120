import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";
import { goBack } from "connected-react-router";

const apiNamespace = "terms";

const getTermsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TERMS,
});
const getTermLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TERM,
});
const updateTermsLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_TERMS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb:(dispatch) => {
    dispatch(goBack());
  }
});
const createTermLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_TERM,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb:(dispatch) => {
    dispatch(goBack());
  }
  
});
const deleteTermsLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_TERMS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
 
  
});

export default [
    getTermsLogic,
    deleteTermsLogic,
    updateTermsLogic,
    getTermLogic,
    createTermLogic
];
