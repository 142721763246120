import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import notificationActions from "src/store/notification/actions";

import { Trans } from "@lingui/react";
import { push } from "connected-react-router";

const apiNamespace = "request";

const getNewRequestLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_NEW_REQUEST,
});

const getRequestLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REQUEST,
  successCb: (dispatch) => {
    dispatch(
      notificationActions.recieveNotification({
        title: "Your request is finished",
        body: "the request",
        link: "requests/list",
      })
    );
  },
});
const getRequestsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REQUESTS,
});

export default [getNewRequestLogic, getRequestLogic, getRequestsLogic];
