import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const initialState = {
  ...defaultState("services", { metadata: undefined }, []),
  ...defaultState("service", { metadata: undefined }, {}),
   ...defaultState("childService", { metadata: undefined }, {}),
  ...defaultState("serviceOption", { metadata: undefined }, {}),
  ...defaultState("serviceTypes", { metadata: undefined }, []),
  ...defaultState("vouchers", { metadata: undefined }, []),
  ...defaultState("availableServices", { metadata: undefined }, []),
  ...defaultState("isOpenSweetAlert", { metadata: undefined }, false),
  ...defaultState("types", { metadata: undefined }, []),
  ...defaultState("serviceTransactions", { metadata: undefined }, []),
  ...defaultState("voucherValidation", { metadata: undefined }, undefined),
  ...defaultState("voucherSubmit", { metadata: undefined }, undefined),
  ...defaultState("serviceProviders", { metadata: undefined }, []),
  ...defaultState("serviceChildren", { metadata: undefined }, []),
  ...defaultState("optionVouchers", { metadata: undefined }, []),
  ...defaultState("integrationServices", { metadata: undefined }, []),
  ...defaultState("serviceOptions", { metadata: undefined }, {}),









};
const setOption = (state, draft, payload) => {
  draft.serviceOption = undefined;
};

const setService = (state, draft, payload) => {
  draft.service = undefined;
};
const setRedeem = (state, draft, payload) => {
  draft.redeemResult = undefined;
  draft.redeemResult_errors = undefined;
  draft.isOpenSweetAlert = false;
};
const setSubmitRedeem = (state, draft, payload) => {
  draft.voucherSubmit = undefined;
  draft.voucherSubmit_errors = undefined;
  draft.isOpenSweetAlert = false;
};
setServiceProvider
const setServiceProvider = (state, draft, payload) => {
  draft.serviceProvider = undefined;
 
};
const validateVoucherFailure = (state, draft, payload) => {
  draft.voucherValidation = undefined;

};
const submitRedeemVoucherSuccess = (state, draft, payload) => {
  draft.isOpenSweetAlert = true;

};
const submitRedeemVoucherFailure = (state, draft, payload) => {
  draft.isOpenSweetAlert = true;

};
const validateVoucherSuccess = (state, draft, payload) => {
  draft.voucherValidation_errors = undefined;

};
const redeemSuccess = (state, draft, payload) => {
  draft.openDialog = false;
  draft.isOpenSweetAlert = true;
};
const redeemFailure = (state, draft, payload) => {
  draft.isOpenSweetAlert = true;
};
const createServiceTypeSuccess=(state, draft, payload) => {
const types=state.types||[];
draft.types=[...types,payload];
draft.openDialog = false;
}
const updateServiceTypeSuccess=(state, draft, payload) => {
  const index = state.types.findIndex(r => r.id === payload.id)
  if (index >= 0) {
    draft.types[index] = payload;
  }
  draft.openDialog = false;
  }
  const serviceTypeDeleteSuccess=(state, draft, payload) => {
    const types = state.types || [];
    draft.types = types.filter((a) => a.id != payload);
    draft.openDeleteDialog = false;
    }
const serviceDeleteSuccess = (state, draft, payload) => {
  const services = state.services || [];
  draft.services = services.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};
const serviceProviderDeleteSuccess=(state, draft, payload) => {
  const serviceProviders = state.serviceProviders || [];
  draft.serviceProviders = serviceProviders.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};
const serviceOptionDeleteSuccess = (state, draft, payload) => {
 // debugger
  const options = state.serviceOptions?.options || [];
  draft.serviceOptions.options = options.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "checkParentProvider",
    stateName: "parentProvider",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getServiceChildren",
    stateName: "serviceChildren",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getServicesIntegration",
    stateName: "integrationServices",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getServiceOptionVouchers",
    stateName: "optionVouchers",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getServiceOptions",
    stateName: "serviceOptions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "redeem",
    stateName: "redeemResult",
    async: true,
    isPaginated: false,
    successCb: redeemSuccess,
    failCb: redeemFailure,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllServices",
    stateName: "services",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getServiceProviderWithId",
    stateName: "serviceProvider",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getServiceProviders",
    stateName: "serviceProviders",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "validateVoucher",
    stateName: "voucherValidation",
    async: true,
    isPaginated: false,
    successCb:validateVoucherSuccess,
    failCb:validateVoucherFailure
  }),
  ...bindReducers(ActionTypes, {
    action: "submitRedeemVoucher",
    stateName: "voucherSubmit",
    async: true,
    isPaginated: false,
    successCb:submitRedeemVoucherSuccess,
    failCb:submitRedeemVoucherFailure
  }),
  ...bindReducers(ActionTypes, {
    action: "getServiceTransactions",
    stateName: "serviceTransactions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
  
    action: "getTypes",
    stateName: "types",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getServiceWithId",
    stateName: "service",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createService",
    stateName: "service",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createChildService",
    stateName: "childService",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createServiceProvider",
    stateName: "serviceProvider",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createServiceType",
    stateName: "serviceType",
    async: true,
    isPaginated: false,
    successCb:createServiceTypeSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "updateService",
    stateName: "service",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateChildService",
    stateName: "childService",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateServiceType",
    stateName: "serviceType",
    async: true,
    isPaginated: false,
    successCb:updateServiceTypeSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "updateServiceProvider",
    stateName: "serviceProvider",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteService",
    stateName: "service",
    async: true,
    isPaginated: false,
    successCb: serviceDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteServiceType",
    stateName: "serviceType",
    async: true,
    isPaginated: false,
    successCb: serviceTypeDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteServiceProvider",
    stateName: "serviceProvider",
    async: true,
    isPaginated: false,
    successCb: serviceProviderDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "setService",
    stateName: "service",
    async: false,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getServiceOptionWithId",
    stateName: "serviceOption",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createServiceOption",
    stateName: "serviceOption",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAvailableServices",
    stateName: "availableServices",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createVouchers",
    stateName: "vouchers",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateServiceOption",
    stateName: "serviceOption",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteServiceOption",
    stateName: "serviceOption",
    async: true,
    isPaginated: false,
    successCb: serviceOptionDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getServiceTypes",
    stateName: "serviceTypes",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setOption",
    stateName: "serviceOption",
    async: false,
    isPaginated: false,
    successCb: setOption,
  }),
  ...bindReducers(ActionTypes, {
    action: "setService",
    stateName: "service",
    async: false,
    isPaginated: false,
    successCb: setService,
  }),
  ...bindReducers(ActionTypes, {
    action: "setServiceProvider",
    stateName: "serviceProvider",
    async: false,
    isPaginated: false,
    successCb: setServiceProvider,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "setRedeem",
    stateName: "setRedeem",
    async: false,
    isPaginated: false,
    successCb: setRedeem,
  }),
  ...bindReducers(ActionTypes, {
    action: "setSubmitRedeem",
    stateName: "setSubmitRedeem",
    async: false,
    isPaginated: false,
    successCb: setSubmitRedeem,
  }),
});
