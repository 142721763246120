import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("terms", { metadata: undefined }, {}),
  ...defaultState("term", { metadata: undefined }, {}),
}

const setTerm=(state,draft,payload)=>{
  draft.term=undefined;
}
const termDeleteSuccess = (state, draft, payload) => {
  // debugger;
   const terms = state.terms || [];
   draft.terms = terms.filter((a) => a.id != payload.id);
   draft.term=undefined;
   draft.openDeleteDialog = false;
 };
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};


export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getTerms",
    stateName: "terms",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateTerms",
    stateName: "term",
    async: true,
    isPaginated: false,
   // successCb:termsCreatedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "createTerm",
    stateName: "term",
    async: true,
    isPaginated: false,
    //successCb:termsCreatedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "getTerm",
    stateName: "term",
    async: true,
    isPaginated: false,
    //successCb:termsCreatedSuccess
  }),

  ...bindReducers(ActionTypes, {
    action: "deleteTerms",
    stateName: "term",
    async: true,
    isPaginated: false,
    successCb:termDeleteSuccess
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "setTerm",
    stateName: "term",
    async: false,
    isPaginated: false,
    successCb: setTerm,
  }),
});
