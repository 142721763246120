import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

const apiNamespace = "Advertisements";

const getAdvertisementLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ADVERTISEMENT,
});
const saveAdvertisementLogic = logic(apiNamespace, {
  actionName: ActionTypes.SAVE_ADVERTISEMENT,
  showerrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const updateAdvertisementLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_ADVERTISEMENT,
  showerrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});
const deleteAdvertisementLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_ADVERTISEMENT,
  showerrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [getAdvertisementLogic, saveAdvertisementLogic,deleteAdvertisementLogic,updateAdvertisementLogic];
