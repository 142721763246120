import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("scibWalletTransactions", { metadata: undefined }, []),
  ...defaultState("addedPoints", { metadata: undefined }, 0),
  ...defaultState("points", { metadata: undefined }, {}),
  ...defaultState("updatedPoints", { metadata: undefined }, 0),
  //...defaultState("dest", { metadata: undefined }, ""),
};
const setDestination = (state, draft, payload) => {
  // debugger
  draft.destination.destId = payload?.destId;
  draft.destination.mainWallet = payload?.mainWallet;
};
const addPointsSuccess = (state, draft, payload) => {
  let tenantBalance = state.points.tenantWallet.balance;

  let mainWallet = state.destination.mainWallet;
  draft.points.tenantWallet.balance = mainWallet
    ? tenantBalance + payload.amount
    : tenantBalance - payload.amount;
  if (!mainWallet) {
    let serviceWallet = state.destination.destId;
    let index = state.points.services.findIndex(
      (e) => e.walletId == serviceWallet
    );
    draft.points.services[index].balance += payload.amount;
  }

  const scibWalletTransactions = state.scibWalletTransactions || [];
  draft.transactions = [...scibWalletTransactions, payload];
  draft.updatedPoints = payload?.balance;
  draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};

const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getScibWalletTransactions",
    stateName: "scibWalletTransactions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "addPoints",
    stateName: "addedPoints",
    async: true,
    isPaginated: false,
    successCb: addPointsSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getPoints",
    stateName: "points",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "setDestination",
    stateName: "destination",
    async: false,
    isPaginated: false,
    successCb: setDestination,
  }),
});
