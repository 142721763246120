export default function (/**@type {ApisauceInstance} */ api) {
  const getUsers = (paging) => api.get("/user-management/users/search", paging);
  const register = (loginRequest) => api.post("auth/register", loginRequest);
  const verifyOtp = (paging) => api.post("auth/verify-otp", paging);
  const resendOtp = (paging) => api.post("auth/resend-otp", paging);
  const getProfileData = () => api.get("/user-management/client-profile-data");
  const updateUserAvatar = (payload) => api.put("/user-management/users/update-avatar",payload);
  const getPermissions = () => api.get("/user-management/permissions");
  const handleSeen = (payload) => api.post("/user-management/seen",payload);
  const createUser = (payload) =>
    api.post("user-management/users/create", payload);
  const createUsers = (payload) =>
    api.post("user-management/add-users", payload);
  const createAppUser = (payload) =>
    api.post("user-management/users/app-user", payload);
    const createClient= (payload) =>
    api.post("user-management/users/create-client", payload);
    const editClient= ({ payload, id }) =>
    api.put("user-management/users/edit-client/"+id, payload);
  const getUser = (id) => api.get(`user-management/users/find/${id}`);
  const getRoles = (paging) => api.get("user-management/roles", paging);
  const deleteUser = (id) =>
    api.delete(`user-management/users/delete-user/${id}`);
  const createRole = (payload) =>
    api.post("user-management/roles/create", payload);
  const editUser = ({ payload, id }) =>
    api.put(`user-management/users/edit/${id}`, payload);
  const deleteRole = (id) =>
    api.delete(`user-management/roles/remove/${id}`);
  const editRole = ({ payload, id }) =>
    api.put(`user-management/roles/edit/${id}`, payload);
  const getRole = (id) => api.get(`user-management/roles/find/${id}`);
  const addClientDecision = (payload) =>
    api.post(`user-management/users/decision`, payload);
  return {
    userManegment: {
      register,
      handleSeen,
      verifyOtp,
      resendOtp,
      getUsers,
      getProfileData,
      getUser,
      createUser,
      createClient,
      editClient,
      createUsers,
      updateUserAvatar,
      createAppUser,
      deleteUser,
      getRoles,
      editUser,
      createRole,
      getPermissions,
      deleteRole,
      editRole,
      getRole,
      addClientDecision,
    },
  };
}
