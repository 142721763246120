import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("productAndCodesCount", { metadata: undefined }, undefined),
  ...defaultState("usersCount", { metadata: undefined }, undefined),
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};
export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "generateFile",
    stateName: "file",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "clearFile",
    stateName: "file",
    async: false,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getReportsList",
    stateName: "reportsList",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getProductAndCodesCount",
    stateName: "productAndCodesCount",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getLastDaysSummaryOfRedemptionAndScan",
    stateName: "summaryOfRedemptionAndScan",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getServicesRedemptionSummary",
    stateName: "servicesRedemptionSummary",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTopTenScannedProductsQuery",
    stateName: "topTenScannedProducts",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getUsersCount",
    stateName: "usersCount",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
