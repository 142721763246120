//** React Imports
import { useEffect, useState } from 'react'
// ** Store & Actions
import { LayoutActions } from 'src/store/layout/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Roles } from 'src/configs/roles'
import { useNavbarType } from './useNavbarType'
import themeConfig from 'src/configs/themeConfig'
import { AuthActions } from 'src/store/auth/actions'

export const useUser = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const [navbarType, setNavbarType] = useNavbarType()
    const { user } = useSelector(state => state.auth)
    const [permissions, setPermissions] = useState([])
    const [tenantId, setTenantId] = useState("")

    const [roles, setRoles] = useState([])
    // ** Return a wrapped version of useState's setter function
    const can = value => {
        return permissions.includes(value)
    }
    const inRole = (role) => {
        return roles.includes(role);
    }
    useEffect(() => {
        console.log("loading")

    }, [])
    useEffect(() => {
        setPermissions(user?.permissions || []);
        setRoles(user?.role || []);
        setTenantId(user?.tenantId || "")
        if (user) {
            dispatch(LayoutActions.handleMenuHidden(user?.role?.includes(Roles.Employee) || false))
        }
        if (user?.role) {
            const rls = JSON.parse(user.role);
            rls.forEach(r => {
                if (!r.includes("Client")) {
                    dispatch(AuthActions.logout());
                }
            })
        }

    }, [user])
    useEffect(() => {
        const navType = inRole(Roles.Employee) ? "sticky" : "floating";

        localStorage.setItem("navbarType", navType)
        setNavbarType(navType)
    }, [roles])

    return {
        can: can,
        user: user,
        permission: permissions,
        roles: roles,
        inRole,
        tenantId
    };
}
