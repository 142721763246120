import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

const apiNamespace = "attribute";

const getAllAttributesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_ATTRIBUTES,
});
const getAttributeWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ATTRIBUTE_WITH_ID,
});
const createAttributeLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_ATTRIBUTE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  
});

const updateAttributeLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_ATTRIBUTE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteAttributeLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_ATTRIBUTE,
});

export default [
  getAllAttributesLogic,
  getAttributeWithIdLogic,
  createAttributeLogic,
  updateAttributeLogic,
  deleteAttributeLogic,
];
