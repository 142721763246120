export default function (/**@type {ApisauceInstance} */ api) {
  const getAllServices = (paging) => api.get("/Service/search", paging);
  const getServicesIntegration = () => api.get("/Aggregator/Integration-services");
  const getServiceOptionVouchers = (paging) => api.get("/Service/service-option-vouchers", paging);
  const getServiceWithId = (id) => api.get("/Service/find/" + id);
  const getServiceProviderWithId = (id) => api.get("/Service/service-provider/find/" + id);
  const getServiceChildren = (id) => api.get("/Service/search-with-parent/" + id);
  const getServiceOptions = (id) => api.get("/Service/service-options/" + id);
  const getServiceTransactions= (payload) => api.get("/wallet-management/service-transactions" , payload);
  const redeem=(payload)=>api.post("/redeem/voucher" , payload);
  const getServiceTypes = () => api.get("/Service/all-services");
  const getTypes = () => api.get("/Service/all-types");
  const getServiceProviders=(paging)=>api.get("/Service/all-service-providers",paging);
  const getAvailableServices = (paging) => api.get("/Service/user-available-service",paging);
  const checkParentProvider = (id) => api.get("/Service/check-parent-provider/"+id);
  const validateVoucher = (payload) => api.get("/Redeem/validate-voucher",payload);
  const submitRedeemVoucher = (paging) => api.post("/Redeem/submit-voucher",paging);
  const getServiceOptionWithId=(id)=>api.get("/Service/find-option/" + id)
  const createService = (payload) =>api.post("/Service/create", payload);
  const createChildService = (payload) =>api.post("/Service/create-child-service", payload);
  const createServiceOption = (payload) =>api.post("/Service/create-option", payload);
  const createServiceType= (payload) =>api.post("/Service/create-service-type", payload);
  const createServiceProvider= (payload) =>api.post("/Service/create-service-provider", payload);
  const createVouchers= (payload) =>api.post("/Service/create-vouchers", payload);
  const updateService = (payload) => api.put("/Service/edit", payload);
  const updateChildService = (payload) => api.put("/Service/edit", payload);
  const updateServiceType = (payload) => api.put("/Service/edit-service-type", payload);
  const updateServiceProvider = (payload) => api.put("/Service/edit-service-provider", payload);
  const updateServiceOption = (payload) => api.put("/Service/edit-option", payload);
  const deleteService = (id) => api.delete("/Service/remove/" + id);
 const deleteServiceOption = (id) => api.delete("/Service/remove-option/" + id);
 const deleteServiceType= (id) => api.delete("/Service/service-type/remove/" + id);
 const deleteServiceProvider= (id) => api.delete("/Service/service-provider/remove/" + id);




  return {
    service: {
      checkParentProvider,
      validateVoucher,
      submitRedeemVoucher,
      getAllServices,
      getServicesIntegration,
      getServiceOptionVouchers,
      getServiceProviderWithId,
      getTypes,
      getServiceProviders,
      getServiceChildren,
      getServiceOptions,
      getServiceWithId,
      getAvailableServices,
      getServiceTransactions,
      createService,
      createChildService,
      createServiceType,
      createVouchers,
      createServiceProvider,
      updateService,
      updateChildService,
      updateServiceProvider,
      updateServiceType,
      deleteService,
      deleteServiceType,
      getServiceOptionWithId,
      createServiceOption,
      updateServiceOption,
      deleteServiceOption,
      deleteServiceProvider,
      getServiceTypes,
      redeem

    },
  };
}
