
import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllSuggestions: { args: ["payload"], meta: { async: true } },
    createSuggestion: { args: ["payload"], meta: { async: true } },
    handleSeen: { args: ["payload"], meta: { async: true } },
    getSuggestionWithId: { args: ["payload"], meta: { async: true } },
    deleteSuggestion: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    createContactUs: {args: ["payload"], meta: { async: true } }
  },
  {
    prefix: "@app/suggestion/"
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions=Creators;

    