import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useSignalR } from '../hooks/useSignalR';
import notificationsActions from "src/store/notification/actions";
import { useUser } from '../hooks/useUser';
import { getClippingParents } from '@fullcalendar/core';
import clientActions from 'src/store/client/actions';

export const SignalProvider = React.createContext();

function SignalRProvider({ children, ...props }) {

    const { startHub, onReceiveNotify ,closeHub} = useSignalR();
  const dispatch = useDispatch();
  const {getNotifications, readNotification,setNotifications} = notificationsActions;
  const { notifications } = useSelector((state) => state.auth);
  const { getClientInfo} = clientActions;
// const {clientinfo}=useSelector(state = > (state).client);
    const {
        user
    } = useSelector(state => state.auth)
  
    React.useEffect(() => {
        if (user) {
            const token = localStorage.getItem("jwtToken");
            startHub(token);
            // onReceiveNotify();
            return;
        }
        
        //closeHub();
    }, [user])
    React.useEffect(()=>{
       //  dispatch(getNotifications({page: 1, pageSize: 1000 }))
       dispatch(getClientInfo());
  },[])

    return (
        <SignalProvider.Provider
            value={{ ...props, startHub: startHub, onReceiveNotify: onReceiveNotify }}
        >
            {children}
        </SignalProvider.Provider>
    )
}

export default SignalRProvider
