import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";
import { TramOutlined } from "@material-ui/icons";


const initialState = {
  ...defaultState("advertisement", { metadata: undefined }, undefined),

};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};
const setAdvertisement = (state, draft, payload) => {
  draft.advertisement = undefined;
};

export const reducer = createReducer(initialState, {

  ...bindReducers(ActionTypes, {
    action: "getAdvertisement",
    stateName: "advertisement",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "saveAdvertisement",
    stateName: "advertisement",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateAdvertisement",
    stateName: "advertisement",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteAdvertisement",
    stateName: "advertisement",
    async: TramOutlined,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setAdvertisement",
    stateName: "advertisement",
    async: false,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),


});
