import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getRequests: { args: ["payload"], meta: { async: true } },
    getNewRequest: { args: ["payload"], meta: { async: true } },
    getRequest: { args: ["payload"], meta: { async: true } },
    setCurrentPage: { args: ["payload"], meta: { async: false } },
    startRequest: { args: ["payload"], meta: { async: false } },
    filterPageRequest: { args: ["payload"], meta: { async: false } },
    addRequstToList: { args: ["payload"], meta: { async: false } },
    setRequest: { args: ["payload"], meta: { async: false } },

  },
  {
    prefix: "@app/requests/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
