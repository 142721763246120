import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    checkParentProvider: { args: ["payload"], meta: { async: true } },
    getServiceChildren: { args: ["payload"], meta: { async: true } },
    getServicesIntegration: { args: ["payload"], meta: { async: true } },
    getServiceOptions: { args: ["payload"], meta: { async: true } },
    getServiceOptionVouchers: { args: ["payload"], meta: { async: true } },
    getAllServices: { args: ["payload"], meta: { async: true } },
    getTypes: { args: ["payload"], meta: { async: true } },
    getServiceProviders: { args: ["payload"], meta: { async: true } },
    getServiceProviderWithId: { args: ["payload"], meta: { async: true } },
    getServiceTypes: { args: ["payload"], meta: { async: true } },
    getAvailableServices: { args: ["payload"], meta: { async: true } },
    redeem: { args: ["payload"], meta: { async: true } },
    getServiceOptionWithId: { args: ["payload"], meta: { async: true } },
    getServiceWithId: { args: ["payload"], meta: { async: true } },
    getServiceTransactions: { args: ["payload"], meta: { async: true } },
    validateVoucher: { args: ["payload"], meta: { async: true } },
    submitRedeemVoucher: { args: ["payload"], meta: { async: true } },
    createService: { args: ["payload"], meta: { async: true } },
    createChildService: { args: ["payload"], meta: { async: true } },
    createServiceOption: { args: ["payload"], meta: { async: true } },
    createServiceProvider: { args: ["payload"], meta: { async: true } },
    createServiceType: { args: ["payload"], meta: { async: true } },
    createVouchers: { args: ["payload"], meta: { async: true } },
    updateService: { args: ["payload"], meta: { async: true } },
    updateChildService: { args: ["payload"], meta: { async: true } },
    updateServiceType: { args: ["payload"], meta: { async: true } },
    updateServiceProvider: { args: ["payload"], meta: { async: true } },
    updateServiceOption: { args: ["payload"], meta: { async: true } },
    deleteService: { args: ["payload"], meta: { async: true } },
    getServiceOptions: { args: ["payload"], meta: { async: true } },
    deleteServiceOption: { args: ["payload"], meta: { async: true } },
    deleteServiceProvider: { args: ["payload"], meta: { async: true } },
    deleteServiceType: { args: ["payload"], meta: { async: true } },
    setOption: { args: ["payload"], meta: { async: false } },
    setService: { args: ["payload"], meta: { async: false } },
    
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    setSubmitRedeem: { args: ["payload"], meta: { async: false } },
    setServiceProvider: { args: ["payload"], meta: { async: false } },
    setRedeem: { args: ["payload"], meta: { async: false } },

  },
  {
    prefix: "@app/service/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
