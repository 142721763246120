import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";
import { push } from "connected-react-router";


const apiNamespace = "productSku";

const getAllProductSkusLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_PRODUCT_SKUS,
});
const getProductSkuWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PRODUCT_SKU_WITH_ID,
});
const createProductSkuLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_PRODUCT_SKU,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/sku/list"));
  },
});
const updateProductSkuLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_PRODUCT_SKU,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/sku/list"));
  },
});
const deleteProductSkuLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_PRODUCT_SKU,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllProductSkusLogic,
  getProductSkuWithIdLogic,
  createProductSkuLogic,
  updateProductSkuLogic,
  deleteProductSkuLogic,
];
