import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
 // messages:[]
};
const getOpennedChatFailure= (state, draft, payload) => {
  draft.opennedChat=undefined
};

const setItem = (state, draft, payload) => {
  draft.opennedChat?.messages?.push(payload);
};
const startChatSuccess=(state, draft, payload)=>{
 // localStorage.setItem('chatId', payload.chatId)
}
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const setNotifications=(state, draft, payload) => {
  //debugger;
draft.notifications?.unshift(payload)
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};
const readNotificationSuccess=(state, draft, payload) => {
  //debugger
  const index = state.notifications.findIndex(r => r.id === payload.id)
  if (index >= 0) {
    draft.notifications[index] = payload;
  }
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "sendNotification",
    stateName: "sendNotification",
    async: true,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "getNotifications",
    stateName: "notifications",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "readNotification",
    stateName: "readNotificationRes",
    async: true,
    isPaginated: false,
    successCb:readNotificationSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "setNotifications",
    stateName: "setNotificationsRes",
    async: false,
    isPaginated: false,
    successCb:setNotifications
  }),
  ...bindReducers(ActionTypes, {
    action: "startChat",
    stateName: "opennedChat",
    async: true,
    isPaginated: false,
   // successCb:startChatSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "sendMessage",
    stateName: "sendMessageResult",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "joinChat",
    stateName: "joinedChat",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setItem",
    stateName: "item",
    async: false,
    isPaginated: false,
    successCb: setItem,
  }),
  ...bindReducers(ActionTypes, {
    action: "getOpennedChat",
    stateName: "opennedChat",
    async: true,
    isPaginated: false,
    failCb:getOpennedChatFailure
    //successCb: getOpennedChatSuccess,

  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "getChats",
    stateName: "chats",
    async: true,
    isPaginated: true,
  }),
});
