import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { push } from "connected-react-router";
import { Trans } from "@lingui/react";

const apiNamespace = "notification";

const sendNotificationLogic = logic(apiNamespace, {
  actionName: ActionTypes.SEND_NOTIFICATION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="notification_sended_successfully" />,
});
const getChatsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CHATS,
});
const getNotificationsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_NOTIFICATIONS,
});
const joinChatLogic = logic(apiNamespace, {
  actionName: ActionTypes.JOIN_CHAT,
});

const getOpennedChatLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_OPENNED_CHAT,
});
const startChatLogic = logic(apiNamespace, {
  actionName: ActionTypes.START_CHAT,
});
const sendMessageLogic = logic(apiNamespace, {
  actionName: ActionTypes.SEND_MESSAGE,
});
const readNotificationLogic = logic(apiNamespace,{
  actionName: ActionTypes.READ_NOTIFICATION,
});

export default [
  sendNotificationLogic,
  getNotificationsLogic,
  readNotificationLogic,
  getChatsLogic,
  startChatLogic,
  sendMessageLogic,
  joinChatLogic,
  getOpennedChatLogic
];
