export default function (/**@type {ApisauceInstance} */ api) {
  const getUserTransactions = (id) =>
    api.get("/wallet-management/user-transactions/", id);

  const getTransactionsWithServiceId = (id) =>
    api.get("/wallet-management/service-transactions/", id);
const chargeProviderWallet=(payload)=>api.post("/wallet-management/charge-provider-wallet",payload) 
  const getTransactionsWithOwnerId = (id) =>
    api.get("/wallet-management/owner-transactions/", id);
    const getTransactionWithWalletId = (id) =>
    api.get("/wallet-management/wallet-transactions" ,id);
    const getTransactionWithId = (id) =>
    api.get("/wallet-management/transaction/" + id);
    const exportTransactionsWithOwnerToExcel = (payload) =>
    api.get("/wallet-management/report-transactions-ownerId" , payload);
    const exportTransactionsWithServiceToExcel = (payload) =>
    api.get("/wallet-management/report-transactions-serviceId" , payload);
    const exportTransactionsWithWalletToExcel = (payload) =>
    api.get("/wallet-management/report-transactions-walletId" , payload);
    const exportUsersTransactionsSummary = (payload) =>
    api.get("/wallet-management/users-transactins-summary" , payload);
  const getClientPoints = (id) =>
    api.get("/wallet-management/client-points/" + id);
 

  const createGiftPoints = (payload) =>
    api.post("/wallet-management/create-gift/", payload);

  const scanCode = (payload) =>
    api.post("/wallet-management/scan-for-client/", payload);

  return {
    transaction: {
      getUserTransactions,
      chargeProviderWallet,
      getTransactionsWithServiceId,
      getTransactionWithId,
      getTransactionsWithOwnerId,
      getTransactionWithWalletId,
      exportTransactionsWithOwnerToExcel,
      exportTransactionsWithServiceToExcel,
      exportTransactionsWithWalletToExcel,
      exportUsersTransactionsSummary,
      createGiftPoints,
      getClientPoints,
      scanCode,
    },
  };
}
