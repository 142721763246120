import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";
const initialState = {
  ...defaultState("codeBatches", { metadata: undefined }, []),
  ...defaultState("codeBatchesList", { metadata: undefined }, []),
  ...defaultState("batchesForUpdate", { metadata: undefined }, []),


};
const assignCodesSuccesfully = (state, draft, payload) => {
  draft.assigningCodes = null;
};
const setUploadedCodes = (state, draft, payload) => {
  draft.uploadedCodes = undefined;
};

const setItem = (state, draft, payload) => {
  const { item, data } = payload;
  draft[item] = data;
};

const clear = (state, draft, payload) => {
  draft.codes_errors = [];
  draft.codes = null;
  draft.allowablePrefix_errors = [];
  draft.allowablePrefix = null;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "assignCode",
    stateName: "assigningCodes",
    async: true,
    isPaginated: false,
    successCb: assignCodesSuccesfully,
  }),
  ...bindReducers(ActionTypes, {
    action: "searchCode",
    stateName: "codes",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createCode",
    stateName: "code",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "uploadCodes",
    stateName: "uploadedCodes",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setCode",
    stateName: "code",
    async: false,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setUploadedCodes",
    stateName: "setUploadedCodes",
    async: false,
    isPaginated: false,
    successCb:setUploadedCodes
  }),
  ...bindReducers(ActionTypes, {
    action: "setItem",
    stateName: "setItem",
    async: false,
    isPaginated: false,
    successCb: setItem,
  }),
  ...bindReducers(ActionTypes, {
    action: "clear",
    stateName: "clear",
    async: false,
    isPaginated: false,
    successCb: clear,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllowablePrefix",
    stateName: "allowablePrefix",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllowableStart",
    stateName: "allowableStart",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllowableCount",
    stateName: "allowableCount",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getCodeBatches",
    stateName: "codeBatches",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getBatchesForUpdate",
    stateName: "batchesForUpdate",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateBatch",
    stateName: "batch",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getBatchWithId",
    stateName: "batch",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getCodeBatchesWithoutPagination",
    stateName: "codeBatchesList",
    async: true,
    isPaginated: false,
  }),
});
