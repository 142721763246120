export default function (/**@type {ApisauceInstance} */ api) {
  const createCode = (payload) => api.post("/code-management/create", payload);
  const assignCode = (payload) => api.post("/code-management/assign", payload);
  const uploadCodes = (payload) => api.post("/code-management/assign-list", payload);
  const searchCode = (props) => api.post("/code-management/code-status", props);
  const getAllowableStart= (prefix) =>
    api.get("/code-management/allowble-start/" + prefix);
  const getAllowablePrefix = (reference) =>
    api.get("/code-management/search-reference/" + reference);
  const getAllowableCount = (prefix) =>
    api.get("/code-management/allowble-lenght/" + prefix);
  const getCodeBatches = (payload) =>
    api.get("/code-management/code-batches", payload);
    const getBatchesForUpdate = (payload) =>
    api.get("/code-management/batches-for-update", payload);
    const getBatchWithId = (id) =>
    api.get("/code-management/find-batch/"+ id);
    const updateBatch = (payload) =>
    api.put("/code-management/update-batch", payload);
    const getCodeBatchesWithoutPagination = (payload) =>
    api.get("/code-management/code-batches-without-pagination", payload);

  return {
    code: {
      searchCode,
      uploadCodes,
      createCode,
      getAllowablePrefix,
      getBatchWithId,
      getAllowableStart,
      getBatchesForUpdate,
      updateBatch,
      assignCode,
      getAllowableCount,
      getCodeBatches,
      getCodeBatchesWithoutPagination
    },
  };
}
