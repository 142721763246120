export default function (/**@type {ApisauceInstance} */ api) {
  const getCompanyInfo = () => api.get(`tenant/find-information`);
  const saveCompanyInfo = (payload) =>
    api.post(`/tenant/create-information`, payload);
  return {
    companyInfos: {
      saveCompanyInfo,
      getCompanyInfo,
    },
  };
}
