
import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllSlaps: { args: ["payload"], meta: { async: true } },
    getSlapWithId: { args: ["payload"], meta: { async: true } },
    createSlap: { args: ["payload"], meta: { async: true } },
    updateSlap: { args: ["payload"], meta: { async: true } },
    deleteSlap: { args: ["payload"], meta: { async: true } },
    setSlap: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },

  },
  {
    prefix: "@app/slap/"
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions=Creators;

    