import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getReportsList: { args: ["payload"], meta: { async: true } },
    getProductAndCodesCount: { args: ["payload"], meta: { async: true } },
    getLastDaysSummaryOfRedemptionAndScan: {
      args: ["payload"],
      meta: { async: true },
    },
    getServicesRedemptionSummary: { args: ["payload"], meta: { async: true } },
    getTopTenScannedProductsQuery: { args: ["payload"], meta: { async: true } },
    getUsersCount: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    clearFile: { args: ["payload"], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    generateFile: { args: ["payload"], meta: { async: true } },
  },
  {
    prefix: "@app/report/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
