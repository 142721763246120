import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllCategories: { args: ["payload"], meta: { async: true } },
    getParentCategories: { args: ["payload"], meta: { async: true } },
    getCategoryWithId: { args: ["payload"], meta: { async: true } },
    createCategory: { args: ["payload"], meta: { async: true } },
    updateCategory: { args: ["payload"], meta: { async: true } },
    deleteCategory: { args: ["payload"], meta: { async: true } },
    setCategory: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/category/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
