export default function (/**@type {ApisauceInstance} */ api) {
    const getScibWalletTransactions = (paging) => api.get("/wallet-management/owner-transactions", paging);
    const addPoints = (paging) => api.post("/wallet-management/add-tenant-points", paging);
    const getPoints = (id) => api.get("/wallet-management/Tenant-points/"+id);


  
    return {
      scibWalletTransactions: {
        getScibWalletTransactions,
        addPoints,
        getPoints
      },
    };
  }
  