import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    createCode: { args: ["payload"], meta: { async: true } },
    uploadCodes:{ args: ["payload"], meta: { async: true } },
    assignCode: { args: ["payload"], meta: { async: true } },
    searchCode: { args: ["payload"], meta: { async: true } },
    setCode: { args: ["payload"], meta: { async: false } },
    setItem: { args: ["payload"], meta: { async: false } },
    clear: { args: ["payload"], meta: { async: false } },
    getAllowablePrefix: { args: ["payload"], meta: { async: true } },
    getAllowableStart: { args: ["payload"], meta: { async: true } },
    getAllowableCount: { args: ["payload"], meta: { async: true } },
    getCodeBatches: { args: ["payload"], meta: { async: true } },
    getBatchesForUpdate: { args: ["payload"], meta: { async: true } },
    updateBatch: { args: ["payload"], meta: { async: true } },
    getBatchWithId: { args: ["payload"], meta: { async: true } },
    getCodeBatchesWithoutPagination: { args: ["payload"], meta: { async: true } },
    setUploadedCodes: { args: ["payload"], meta: { async: false } },


  },
  {
    prefix: "@app/code/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
