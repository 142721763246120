
  
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("questions", { metadata: undefined }, []),
  ...defaultState("question", { metadata: undefined }, {}),
};
const setQuestion = (state, draft, payload) => {
  draft.category = undefined;
};
const questionDeleteSuccess = (state, draft, payload) => {
  const questions = state.questions || [];
  draft.questions = questions.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

export const reducer = createReducer(initialState, {         
  ...bindReducers(ActionTypes, {
     action: "getAllQuestions",
     stateName: "questions",
     async: true,
     isPaginated: true
  }),
  ...bindReducers(ActionTypes, {
     action: "getQuestionWithId",
     stateName: "question",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "createQuestion",
    stateName: "question",
    async: true,
    isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
     action: "updateQuestion",
     stateName: "question",
     async: true,
     isPaginated: false
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteQuestion",
    stateName: "question",
    async: true,
    isPaginated: false,
    successCb:questionDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "setQuestion",
    stateName: "question",
    async: false,
    isPaginated: false,
    successCb:setQuestion,
  }),
  
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
  