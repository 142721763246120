export default function (/**@type {ApisauceInstance} */ api) {
  const getAllProductSkus = (paging) => api.get("/productsku/search", paging);

  const getProductSkuWithId = (id) => api.get("/productsku/find/" + id);

  const createProductSku = (payload) => api.post("/productsku/create", payload);

  const updateProductSku = (payload) => api.put("/productsku/edit", payload);

  const deleteProductSku = (id) => api.delete("/productsku/remove/" + id);

  return {
    productSku: {
      getAllProductSkus,
      getProductSkuWithId,
      createProductSku,
      updateProductSku,
      deleteProductSku,
    },
  };
}
