export default function (/**@type {ApisauceInstance} */ api) {
  const sendNotification = (payload) =>
    api.post("/notification/send-notification", payload);
  const getChats = (payload) =>api.get("/chat/get-chats", payload);
  const getOpennedChat = (payload) =>api.get("/chat/get-openned-chat", payload);
  const getNotifications = (payload) => api.get("notification/my-notification", payload);
  const readNotification = (id) => api.put("notification/read-notification/" + id);
  const startChat = (payload) =>api.post("/chat/start-chat", payload);
  const joinChat = (payload) =>api.post("/chat/join-chat", payload);
  const sendMessage = (payload) =>api.post("/chat/send-message", payload);

  return {
    notification: {
      getNotifications,
      readNotification,
      getOpennedChat,
      sendNotification,
      getChats,
      startChat,
      sendMessage,
      joinChat
    },
  };
}
