export default function (/**@type {ApisauceInstance} */ api) {
    const getAllSuggestions = (paging) => api.get("/suggestion/search", paging);
    const createSuggestion = (payload) => api.post("/suggestion/create", payload);
    const handleSeen = (payload) => api.post("/suggestion/suggestion/seen", payload);
    const createContactUs = (payload) => api.post("/suggestion/contact-us", payload);

  const getSuggestionWithId=(id)=>api.get("/suggestion/find/"+id)
    const deleteSuggestion= (id) => api.delete("/suggestion/remove/" +id);
  
    return {
      suggestion: {
        getSuggestionWithId,
        createSuggestion,
        getAllSuggestions,
        handleSeen,
        deleteSuggestion,
        createContactUs
      },
    };
  }
  