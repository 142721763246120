import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllProductSkus: { args: ["payload"], meta: { async: true } },
    getProductSkuWithId: { args: ["payload"], meta: { async: true } },
    createProductSku: { args: ["payload"], meta: { async: true } },
    updateProductSku: { args: ["payload"], meta: { async: true } },
    deleteProductSku: { args: ["payload"], meta: { async: true } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    setProductSku: { args: ["payload"], meta: { async: false } },
  },
  {
    prefix: "@app/productSku/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
