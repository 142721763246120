export default function (/**@type {ApisauceInstance} */ api) {
    const getAdvertisement= (payload) => api.get(`/Advertisement/search` , payload)
    const saveAdvertisement= (payload) => api.post(`/Advertisement/create`, payload);
    const updateAdvertisement= (payload) => api.put(`/Advertisement/edit`, payload);

    const deleteAdvertisement= (id) => api.delete(`/Advertisement/remove/`+ id);

    return {
        Advertisements: {
        getAdvertisement,
        saveAdvertisement,
        deleteAdvertisement,
        updateAdvertisement
      },
    };
  }
  