import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";
import { toast } from "react-toastify";
const initialState = {
  ...defaultState(
    "requests",
    {
      metadata: undefined,
    },
    []
  ),
  pageRequests: {},
};
const handleSetRequest = (state, draft, payload) => {
 
  draft.request = undefined;
  draft.request_errors=undefined;
};
const handleGetRequests = (state, draft, payload) => {
  let { items } = payload;
  items = items.map((e) => ({
    ...e,
    result: e.response ? handleResponse(e.response) : "",
  }));
  draft.requests = items;
};

const handleResponse = (response) => {
  let parseResponse = JSON.parse(response);
  if (parseResponse?.success)
    return handlePayloadOfResponse(parseResponse?.payload);
  return <div className="text-danger">{parseResponse?.message}</div>;
};
const handlePayloadOfResponse = (payload) => {
  return (
    <div>
      <span className="mx-1">{payload?.comment} </span>
      {payload?.fileUrl && (
        <a
          href={`${process.env.REACT_APP_BASE_URL}/${payload.fileUrl}`}
          target="_blank"
          download
        >
          download
        </a>
      )}
    </div>
  );
};

const handleGetNewRequest = (state, draft, payload) => {
  const { id } = payload;
  draft.pageRequests[state.currentPage] = id;
};

const handleStartRequest = (state, draft, payload) => {
  const { name, id } = payload;
  draft.requests[id] = { name, isCompleted: false };
};

const addRequstToListHandler = (state, draft, payload) => {
 // debugger
  let newRequest = { id: payload, status: "InProgress", result: "" };
  let requests = [newRequest, ...state.requests];
  draft.requests = requests;
};

const handleSuccessGetRequest = (state, draft, payload) => {
 debugger
 draft.request=payload;
 draft.request_errors=undefined
  let requests = [...state.requests];
  let index = requests.findIndex((e) => e.id === payload.requestId);

  requests[index] = {
    ...requests[index],
    status: "Complete",
    result: handlePayloadOfResponse(payload),
  };
  filterPageRequest(state, draft, payload.requestId);
  draft.requests = requests;
  toast.success(`${draft.requests[index]?.name||"your request"} request is complete`)
};

const handleFailGetRequest = (state, draft, payload) => {

  const { codeNumber } = payload;
  if (codeNumber != 99) {
    let requests = [...state.requests];
    let index = requests.findIndex((e) => e.id === payload.requestId);
    requests[index] = {
      ...requests[index],
      status: "Faild",
      result: "Error",
    };
    draft.requests = requests;
    filterPageRequest(state, draft, payload.requestId);
  }
};

const filterPageRequest = (state, draft, requestId) => {
  // debugger;
  let pageRequests = { ...state.pageRequests };
  let keyToRemove = "";

  Object.keys(pageRequests).forEach((key) => {
    if (pageRequests[key] == requestId) keyToRemove = key;
  });

  delete pageRequests[keyToRemove];
  draft.pageRequests = pageRequests;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getNewRequest",
    stateName: "newRequest",
    async: true,
    isPaginated: false,
    successCb: handleGetNewRequest,
  }),
  ...bindReducers(ActionTypes, {
    action: "filterPageRequest",
    stateName: "filterPageRequest",
    async: false,
    isPaginated: false,
    successCb: filterPageRequest,
  }),
  ...bindReducers(ActionTypes, {
    action: "setCurrentPage",
    stateName: "currentPage",
    async: false,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "startRequest",
    stateName: "requestRunning",
    async: false,
    isPaginated: false,
    successCb: handleStartRequest,
  }),
  ...bindReducers(ActionTypes, {
    action: "setRequest",
    stateName: "setRequest",
    async: false,
    isPaginated: false,
    successCb: handleSetRequest,
  }),
  ...bindReducers(ActionTypes, {
    action: "addRequstToList",
    stateName: "requests",
    async: false,
    isPaginated: false,
    successCb: addRequstToListHandler,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRequest",
    stateName: "request",
    async: true,
    isPaginated: false,
    successCb: handleSuccessGetRequest,
    failCb: handleFailGetRequest,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRequests",
    stateName: "requests",
    async: true,
    isPaginated: true,
    successCb: handleGetRequests,
  }),
});
