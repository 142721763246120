
import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";

const apiNamespace = "suggestion";

const getAllSuggestionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_SUGGESTIONS,
});
const handleSeenLogic = logic(apiNamespace, {
  actionName: ActionTypes.HANDLE_SEEN,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const getSuggestionWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SUGGESTION_WITH_ID,
});
const createSuggestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_SUGGESTION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const deleteSuggestionLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SUGGESTION,
});


const createContactUsLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CONTACT_US,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

export default [
  getAllSuggestionsLogic,
  createSuggestionLogic,
  handleSeenLogic,
  getSuggestionWithIdLogic,
  deleteSuggestionLogic,
  createContactUsLogic
];
  
  