import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllProductAttributes:{ args: ["payload"], meta: { async: true } },
    getPublishedProducts:{ args: ["payload"], meta: { async: true } },
    createProductAttribute:{ args: ["payload"], meta: { async: true } },
    updateProductAttribute:{ args: ["payload"], meta: { async: true } },
    deleteProductAttribute:{ args: ["payload"], meta: { async: true } },
    getAllProducts: { args: ["payload"], meta: { async: true } },
    getProductWithId: { args: ["payload"], meta: { async: true } },
    createProduct: { args: ["payload"], meta: { async: true } },
    updateProduct: { args: ["payload"], meta: { async: true } },
    deleteProduct: { args: ["payload"], meta: { async: true } },
    setProduct: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/product/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
