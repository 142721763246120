export default function (/**@type {ApisauceInstance} */ api) {
    const getAllSlaps = (paging) => api.get("/Slabs/search", paging);
  
    const getSlapWithId = (id) => api.get("/Slabs/find/" + id);
  
    const createSlap = (payload) => api.post("/Slabs/create", payload);
  
    const updateSlap = (payload) => api.put("/Slabs/edit", payload);
  
    const deleteSlap = (id) => api.delete("/Slabs/remove/" +id);
  
    return {
      slap: {
        getAllSlaps,
        getSlapWithId,
        createSlap,
        updateSlap,
        deleteSlap,
      },
    };
  }
  