import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getCompanyInfo: { args: [], meta: { async: true } },
    saveCompanyInfo: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/CompanyInfos/",
  }
);
export const ActionTypes = Types;
export default Creators;
