import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    sendNotification: { args: ["payload"], meta: { async: true } },
    getNotifications: { args: ["payload"], meta: { async: true } },
    readNotification: { args: ["payload"], meta: { async: true } },
    setNotifications: { args: ["payload"], meta: { async: false } },
    getChats: { args: ["payload"], meta: { async: true } },
    getOpennedChat: { args: ["payload"], meta: { async: true } },
    startChat: { args: ["payload"], meta: { async: true } },
    joinChat: { args: ["payload"], meta: { async: true } },
    sendMessage: { args: ["payload"], meta: { async: true } },
    setItem: { args: ["payload"], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/store/",
  }
);

export const ActionTypes = Types;
export default Creators;
export const AuthActions = Creators;
