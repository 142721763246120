import codeAction, { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";

const apiNamespace = "code";

const createCodeLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_CODE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="Codes_created_successfully" />,
});

const assignCodeLogic = logic(apiNamespace, {
  actionName: ActionTypes.ASSIGN_CODE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="Codes_assigned_successfully" />,
});

const searchCodeLogic = logic(apiNamespace, {
  actionName: ActionTypes.SEARCH_CODE,
});

const getAllowablePrefixLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALLOWABLE_PREFIX,
});

const getAllowableStartLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALLOWABLE_START,
});
const uploadCodestLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPLOAD_CODES,
});
const getAllowableCountLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALLOWABLE_COUNT,
});
const getCodeBatchesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CODE_BATCHES,
});
const getBatchesForUpdateLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_BATCHES_FOR_UPDATE,
});
const getBatchWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_BATCH_WITH_ID,
});
const updateBatchLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_BATCH,
});
const getCodeBatchesWithoutPaginationLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CODE_BATCHES_WITHOUT_PAGINATION,
});

export default [
  createCodeLogic,
  uploadCodestLogic,
  getAllowableCountLogic,
  getBatchWithIdLogic,
  getAllowablePrefixLogic,
  getAllowableStartLogic,
  assignCodeLogic,
  updateBatchLogic,
  getCodeBatchesLogic,
  getBatchesForUpdateLogic,
  searchCodeLogic,
  getCodeBatchesWithoutPaginationLogic
];
